import React from 'react';
import navigationStyle from './navigation.module.css';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();

  return (
    <div className={navigationStyle.container}>
      <div className={navigationStyle.buttonContainer}>
        <input
          className={navigationStyle.button}
          type="button"
          value="Home"
          onClick={() => navigate('/')}
        />
      </div>
      <div className={navigationStyle.buttonContainer}>
        <input
          className={navigationStyle.button}
          type="button"
          value="Wallet"
          onClick={() => navigate('/wallet/step-type')}
        />
      </div>
      <div className={navigationStyle.buttonContainer}>
        <input
          className={navigationStyle.button}
          type="button"
          value="BodyWeight"
          onClick={() => navigate('/body-weight')}
        />
      </div>
    </div>
  );
};

export default Navigation;