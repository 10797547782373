import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from './../config';
import walletStyle from './wallet.module.css';

const Wallet = () => {
  const [companies, setCompanies] = useState([]);
  const [types, setTypes] = useState([
    {
      id: 1,
      name: 'Przchód'
    },
    {
      id: 2,
      name: 'Wydatki'
    }
  ]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  async function getCompanies() {
    try {
      const response = await axios.get(`${API_URL}company`);

      if (response.status === 200) {
        setCompanies(response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function submit() {
    alert(selectedCompany+', '+selectedType);
    /*try {
      const data = {
        weight: weight
      };

      const response = await axios.post(`${API_URL}weight`, JSON.stringify(data));

      if (response.status === 200) {
        setWeight(0);
        setDisplayWeight('0,0');
        //getWeight();
        alert(response.data.message);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }*/
  }

  const handleSelectedCompany = (event) => {
    const {value, checked} = event.target;
    
    if (checked) {
      setSelectedCompany(value);
    }
  };

  const handleSelectedType = (event) => {
    const {value, checked} = event.target;
    
    if (checked) {
      setSelectedType(value);
    }
  };

  useEffect(() => {
    getCompanies();
  }, [selectedCompany]);

  return (
    <div className={walletStyle.step}>
      <div className={walletStyle.stepGroup}>
        <div className={walletStyle.stepGroupTitle}>Rachunek</div>
        <div className={walletStyle.stepGroupInputs}>
          {companies.map((item, index) => (
            <label key={index} className={`${walletStyle.inputCloudLabel} ${item.id == selectedCompany ? walletStyle.inputCloudActive : ''}`}>
              {item.name}
              <input
                type="radio"
                name="company"
                className={walletStyle.inputCloud}
                value={item.id}
                onChange={handleSelectedCompany}
              />
            </label>
          ))}
        </div>
      </div>
      <div className={walletStyle.stepGroup}>
        <div className={walletStyle.stepGroupTitle}>Rodzaj</div>
        <div className={walletStyle.stepGroupInputs}>
          {types.map((item, index) => (
            <label key={index} className={`${walletStyle.inputCloudLabel} ${item.id == selectedType ? walletStyle.inputCloudActive : ''}`}>
              {item.name}
              <input
                type="radio"
                name="type"
                className={walletStyle.inputCloud}
                value={item.id}
                onChange={handleSelectedType}
              />
            </label>
          ))}
        </div>
        <div className={`tile`}>
          <input
            className={`cta`}
            type="submit"
            value="Dalej"
            onClick={() => submit()}
          />
        </div>
      </div>
    </div>
  );
};

export default Wallet;