import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import base from './base.module.css';
import style from './style.css';
import Navigation from './components/navigation/Navigation.js';
import Wallet from './components/Wallet.js';
import BodyWeight from './components/BodyWeight.js';

function App() {
  const routes = [
    {
      key: 0,
      path: '/',
      elements: [
        <Navigation key={0} />
      ]
    },
    {
      key: 1,
      path: '/wallet/step-type',
      elements: [
        <Navigation key={0} />,
        <Wallet key={1} />
      ]
    },
    {
      key: 2,
      path: '/body-weight',
      elements: [
        <Navigation key={0} />,
        <BodyWeight key={1} />
      ]
    }
  ];

  const routeComponents = routes.map((route) => (
    <Route
      key={route.key}
      path={route.path}
      element={route.elements} />
  ));

  return (
    <div className={`${base.noselect}`}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/">
            {routeComponents}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
