import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from './../../config';

const ModalWeights = (props) => {
  const [lastWeights, setlastWeights] = useState([]);

  async function getLastWeights() {
    try {
      const response = await axios.get(`${API_URL}body-weight`);

      if (response.status === 200) {
        setlastWeights(response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getLastWeights();
  }, [lastWeights]);

  return (
    <>
      {props.isWeightsModalOpen ? (
        <div className={`modal`}>
          <div className={`modal-nav`}>
            <input
              className={`modal-nav-close`}
              type="button"
              value="Close"
              onClick={() => props.setIsWeightsModalOpen(false)}
            />
          </div>
          <div className={`modal-body`}>
            {lastWeights.map((element, index) => (
              <div key={index} className={`row`}>
                <div className={`col text to-left`}>
                  {element.date}
                </div>
                <div className={`col text to-right`}>
                  {props.convertNumberToWeightInKg(element.weight)} kg
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ModalWeights;